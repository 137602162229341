export function autoplayVideos() {
  document.addEventListener("DOMContentLoaded", function () {
    const videoElements = document.querySelectorAll(".autoplay-video");

    videoElements.forEach((video) => {
      function ensureVideoPlays() {
        if (video.readyState >= 4 && video.paused) {
          video.muted = true;
          video.volume = 0.0;
          video.play().catch((error) => {
            // Silently catch the error without logging to console
            // You could add custom error handling here if needed
          });
        }
        setTimeout(ensureVideoPlays, 500);
      }

      video.addEventListener("pause", ensureVideoPlays);
      ensureVideoPlays();
    });
  });
}

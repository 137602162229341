import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isAgeVerified } from "@/core/utils/ageVerification";
import Toast from "@/core/ui/Toast";
const AgeVerification = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState("success");
    useEffect(() => {
        const localVerified = localStorage.getItem("ageVerified");
        const verificationTime = localStorage.getItem("verificationTime");
        if (localVerified === "true" && verificationTime) {
            const currentTime = new Date().getTime();
            const timeDifference = currentTime - parseInt(verificationTime);
            // Check if the verification is within the allowed time frame (1 hour)
            if (timeDifference <= 3600000) {
                setIsVerified(true);
            }
            else {
                localStorage.removeItem("ageVerified");
                localStorage.removeItem("verificationTime");
            }
        }
    }, []);
    const handleVerify = () => {
        if (birthDate) {
            const birthYear = birthDate.getFullYear();
            const birthMonth = birthDate.getMonth() + 1; // Months are zero-indexed
            const birthDay = birthDate.getDate();
            if (isAgeVerified(birthYear, birthMonth, birthDay)) {
                localStorage.setItem("ageVerified", "true");
                localStorage.setItem("verificationTime", new Date().getTime().toString());
                setIsVerified(true);
            }
            else {
                setToastMessage("You must be 21 years of age or older to enter this site.");
                setToastType("error");
            }
        }
        else {
            setToastMessage("Please enter your birth date.");
            setToastType("error");
        }
    };
    if (isVerified) {
        return null;
    }
    return (_jsxs("div", { className: "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75", children: [toastMessage && (_jsx(Toast, { message: toastMessage, type: toastType, onClose: () => setToastMessage(null) })), _jsxs("div", { className: "w-11/12 max-w-lg p-8 mx-auto text-center rounded-lg shadow-lg gradient-anime-alert", children: [_jsx("h2", { className: "mb-4 text-2xl font-bold text-red-600", children: "Age Verification \uD83C\uDF82" }), _jsx("p", { className: "mb-4 font-medium", children: "You must be 21 years of age or older to enter this site." }), _jsx("div", { className: "flex justify-center mb-4 space-x-2", children: _jsx(DatePicker, { selected: birthDate, onChange: (date) => setBirthDate(date), dateFormat: "MM/dd/yyyy", placeholderText: "Select your birth date", className: "p-2 text-center text-gray-800 border rounded lato-bold" }) }), _jsx("button", { className: "px-4 py-2 font-semibold tracking-wide text-white bg-red-600 rounded hover:bg-blue-600", onClick: handleVerify, children: "Verify" })] })] }));
};
export default AgeVerification;

export const LOYALTY_POINTS_CONFIG = {
    earningStructure: {
        pointsPerDollar: 1,
        bonusPoints: {
            signup: 100,
            birthdayMultiplier: 2,
        },
        doublePointDays: true,
        specialPromotions: true,
    },
    rewardsTiers: [
        {
            name: "Green",
            range: [0, 500],
            perks: ["Program enrollment", "Weekly deals", "Birthday bonus points"],
        },
        {
            name: "Silver",
            range: [500, 1000],
            perks: [
                "Monthly member-exclusive deals",
                "Buy-one-get-one 50% off select items",
            ],
        },
        {
            name: "Gold",
            range: [1000, 3000],
            perks: [
                "5% off any purchase",
                "Early access to new products",
                "Quarterly rewards credit ($15)",
            ],
        },
        {
            name: "Platinum",
            range: [3000, Infinity],
            perks: [
                "15% off any purchase",
                "VIP event invitations",
                "Monthly rewards credit ($25)",
                "Priority customer service",
            ],
        },
    ],
    redemptionOptions: [
        { points: 100, reward: "$5 store credit" },
        { points: 250, reward: "10% off purchase" },
        { points: 500, reward: "$25 store credit" },
        { points: 1000, reward: "$50 store credit" },
    ],
    specialFeatures: [
        "View point balance on Customer Dashboard",
        "Email notifications for milestones",
        "Member-only promotions",
        "Monthly reward redemption",
    ],
};

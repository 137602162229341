import { jsx as _jsx } from "react/jsx-runtime";
import HomePage from "@/core/page/Home";
import About from "@/core/page/About";
import Dispensary from "@/core/page/Dispensary";
import Locations from "@/core/Locations";
import EventsPage from "@/core/events/EventsPage";
import PodcastPage from "@/core/Podcast";
import FAQPage from "@/core/FAQs";
import WhatsYourVibe from "@/core/Forms/WhatsYourVibe";
import ContactPage from "@/core/page/ContactPage";
import WashingtonDC from "@/core/Locations/WashingtonDC";
import Login from "@/core/dashboard/Login";
import Register from "@/core/dashboard/Register";
import NotFoundPage from "@/core/error/NotFoundPage";
import AllBlogs from "@/core/Blogs/AllBlogs";
import LocalSEO from "@/core/Blogs/template/LocalSEO";
import blogDataDC from "@/core/Blogs/database_content_updated-dc.json";
import MediaPage from "@/core/page/Media";
// Dashboard and its subcomponents
import Dashboard from "@/core/dashboard/Dashboard";
import LoyaltyPoints from "@/core/page/LoyaltyPoints/index";
const capitalizeDashText = (text) => {
    return text
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};
// Process DC blog data
const dcBlogRoutes = blogDataDC
    .map((blog, index) => {
    // Only create routes for blogs that have the required sk field
    if (!blog.sk)
        return null;
    // Create dataItem with info from the blog object
    const dataItem = {
        subject: blog.subject || "",
        city: blog.city || "Washington",
        state: blog.state || "DC",
    };
    // If we have an sk but don't have explicit subject/city fields, extract them from sk
    if (!blog.subject || !blog.city) {
        const skParts = blog.sk.split("/", 2);
        if (skParts.length > 0 && !blog.subject) {
            dataItem.subject = capitalizeDashText(skParts[0]);
        }
        if (skParts.length > 1 && !blog.city) {
            const cityStateParts = skParts[1].split("-");
            if (cityStateParts.length > 0) {
                dataItem.city = capitalizeDashText(cityStateParts[0]);
            }
        }
    }
    return {
        path: `/blog/${blog.sk}`,
        element: (_jsx(LocalSEO, { title: blog.title ||
                `${dataItem.subject} in ${dataItem.city}, ${dataItem.state}`, metaDescription: blog.metaDescription || "", introParagraph: blog.introParagraph || "", middleParagraph: blog.middleParagraph || "", conclusionParagraph: blog.conclusionParagraph || "", dataItem: dataItem })),
    };
})
    .filter((route) => route !== null);
// Combine both blog route arrays
const blogRoutes = [...dcBlogRoutes];
const routes = [
    {
        path: "/",
        element: _jsx(HomePage, {}),
    },
    {
        path: "/about",
        element: _jsx(About, {}),
    },
    {
        path: "/dispensary",
        element: _jsx(Dispensary, {}),
    },
    {
        path: "/podcast",
        element: _jsx(PodcastPage, {}),
    },
    {
        path: "/faq",
        element: _jsx(FAQPage, {}),
    },
    {
        path: "/washington-dc",
        element: _jsx(WashingtonDC, {}),
    },
    {
        path: "/whats-your-vibe",
        element: _jsx(WhatsYourVibe, {}),
    },
    {
        path: "/media",
        element: _jsx(MediaPage, {}),
    },
    {
        path: "/events",
        element: _jsx(EventsPage, {}),
    },
    {
        path: "/locations",
        element: _jsx(Locations, {}),
    },
    {
        path: "/contact",
        element: _jsx(ContactPage, {}),
    },
    {
        path: "/login",
        element: _jsx(Login, {}),
    },
    {
        path: "/register",
        element: _jsx(Register, {}),
    },
    {
        path: "/dashboard/*",
        element: _jsx(Dashboard, {}),
    },
    {
        path: "loyalty-points",
        element: _jsx(LoyaltyPoints, {}),
    },
    {
        path: "/blogs",
        element: _jsx(AllBlogs, {}),
    },
    ...blogRoutes,
    {
        path: "*",
        element: _jsx(NotFoundPage, {}),
    },
];
export default routes;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import axios from "axios";
import { ENDPOINTS, BASE_API_URL } from "../../api/endpoints";
const Account = () => {
    const [accountData, setAccountData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchAccountData = () => __awaiter(void 0, void 0, void 0, function* () {
            const email = localStorage.getItem("userEmail");
            console.log("Retrieved email from localStorage:", email); // Debugging log
            if (!email) {
                console.error("User email not found in localStorage"); // Debugging log
                setError("User email not found");
                setLoading(false);
                return;
            }
            try {
                console.log("Making API call with email:", email); // Debugging log
                const response = yield axios.post(`${BASE_API_URL}${ENDPOINTS.GET_USER_DATA}`, { email }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const responseData = JSON.parse(response.data.body);
                console.log("API responseData:", responseData); // Debugging log
                setAccountData(responseData);
            }
            catch (err) {
                console.error("API call error:", err); // Debugging log
                setError("Failed to fetch account data");
            }
            finally {
                setLoading(false);
            }
        });
        fetchAccountData();
    }, []);
    if (loading) {
        return _jsx("div", { className: "font-semibold boujee-text", children: "Loading..." });
    }
    if (error) {
        return _jsx("div", { children: error });
    }
    return (_jsxs("div", { className: "text-white", children: [_jsx("h1", { className: "text-3xl font-bold boujee-text", children: "Account" }), _jsx("p", { className: "mt-4 boujee-text", children: "Manage your account details here." }), accountData && (_jsxs("div", { className: "flex flex-col gap-2 mt-6 e", children: [_jsxs("p", { children: [_jsx("strong", { children: "First Name:" }), " ", accountData.firstName || "N/A"] }), _jsxs("p", { children: [_jsx("strong", { children: "Last Name:" }), " ", accountData.lastName || "N/A"] }), _jsxs("p", { children: [_jsx("strong", { children: "Email:" }), " ", accountData.email || "N/A"] }), _jsxs("p", { children: [_jsx("strong", { children: "Phone:" }), " ", accountData.phone || "N/A"] }), _jsxs("p", { children: [_jsx("strong", { children: "Birth Date:" }), " ", accountData.birthDate || "N/A"] }), _jsxs("p", { children: [_jsx("strong", { children: "Accepts Email Marketing:" }), " ", accountData.promoOffers ? "Yes" : "No"] }), _jsxs("p", { children: [_jsx("strong", { children: "Accepts SMS Marketing:" }), " ", accountData.textMessages ? "Yes" : "No"] }), _jsxs("p", { children: [_jsx("strong", { children: "Accepts Event Updates:" }), " ", accountData.eventUpdates ? "Yes" : "No"] })] }))] }));
};
export default Account;

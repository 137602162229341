import { jsx as _jsx } from "react/jsx-runtime";
// src/core/error/NotFoundPage/index.tsx
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
const NotFoundPage = () => {
    // You could also use this to log that a 404 happened before redirecting
    useEffect(() => {
        console.log("404 page not found - redirecting to homepage");
    }, []);
    // Redirect immediately to the homepage
    return _jsx(Navigate, { to: "/", replace: true });
};
export default NotFoundPage;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/features/anime/Ticker.tsx
import { useEffect } from "react";
import { horizontalLoop } from "@/core/utils/horizontal-helper-gsap";
const WelcomeTicker = () => {
    useEffect(() => {
        const ticketItems = document.querySelectorAll(".horizontal-ticker p");
        if (ticketItems.length) {
            horizontalLoop(ticketItems, { speed: 0.3, repeat: -1, paused: false });
        }
    }, []);
    return (_jsx("section", { className: "pt-1.5 text-white lato-regular overflow-x-hidden", children: _jsxs("div", { id: "banner-ticker", className: "flex flex-row items-center justify-between py-2 text-sm border-t-2 border-b-2 border-white horizontal-ticker", children: [_jsx("p", { children: "Premium Cannabis" }), _jsx("p", { children: "Fresh Flower" }), _jsx("p", { children: "Best Baltimore Dispensary" }), _jsx("p", { children: "THC Concentrates" }), _jsx("p", { children: "Edibles" }), _jsx("p", { children: "Best Weed in DC" }), _jsx("p", { children: "Prerolls" }), _jsx("p", { children: "Come In" })] }) }));
};
export default WelcomeTicker;

import { gsap } from "gsap";
import MorphSVGPlugin from "@/core/lib/gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);

const cannabis = `M120.7,202.4c1.4-1,2.7-2,3.9-3
	c0-0.1,0.1-0.1,0.1-0.1c-0.7,1-2.7,3.7-4.1,5.5V202.4z M125.2,198.8c0,0.1-0.1,0.1-0.1,0.2c1.1-0.8,2.1-1.7,3.2-2.4
	c-0.2-0.3-0.5-0.6-0.8-0.8C126.7,196.8,125.9,197.9,125.2,198.8z M121.8,196.3c1,0.8,2,1.6,2.9,2.5l0.2,0.3c0.1-0.1,0.1-0.2,0.1-0.2
	c-0.8-1-1.6-2.1-2.4-3.2C122.3,195.8,122,196,121.8,196.3z M120.7,215c2.3-4.8,3.8-10.1,4.2-15.8c-2,2.6-3.4,4.4-4.2,5.5V215z
	 M124.9,199.2C124.9,199.2,124.9,199.2,124.9,199.2c0.4,5.8,1.8,11,4.2,15.9v-12.6C127.7,201.4,126.3,200.3,124.9,199.2z
	 M78.1,182.1c0.1,0,0.1,0.1,0.2,0.1c-21.6-0.3-42.9,4.6-62.2,14.4c-0.5,0.3-0.9,0.8-1.1,1.3c0,0,0,0,0,0.1c-0.5,1.3,0,2.9,1.2,3.7
	c17.5,8.6,33.8,12.3,46.6,13.6c0,0,0,0,0,0c8.6,0.8,17.4,0.7,26.1-0.4c0.3-0.1,0.4-0.1,0.4-0.2c11.3-1.5,22.1-5.8,31.3-12.4v-3.3
	c0-1.1,0.4-2.1,1.1-2.9C111.5,188.1,96.3,182.7,78.1,182.1z M233.6,196.7c-18.2-9.2-38.2-14.2-58.5-14.5c-0.6,0-1.2,0-1.8,0
	s-1.2,0-1.8,0c-15.6,0.3-30.6,5.3-43.2,14.4c0.5,0.7,0.9,1.6,0.9,2.6v3.3c9.2,6.7,20,11,31.3,12.4c25.2,3,50.7-1.5,73.2-13.1h0
	c0.4-0.3,0.7-0.6,0.9-1c0.2-0.3,0.3-0.6,0.4-0.9c0,0,0,0,0,0c0.1-0.8,0-1.6-0.5-2.3C234.3,197.2,234,196.9,233.6,196.7z M123.2,1.3
	C112.8,25,105,51.9,101.6,79.2c-3.3,26.5-2.5,53.3,4.1,77.8h-0.2c4.3,16,10.4,29.2,17,38.6c0.7-0.4,1.5-0.7,2.3-0.7
	c1,0,1.8,0.3,2.5,0.8c12.2-17.7,22.9-48.4,22.9-86.9c-0.7-37.1-8.7-73.6-23.5-107.6c-0.2-0.5-0.6-0.9-1.1-1.1
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.7,0-1.1,0.1C123.8,0.4,123.4,0.8,123.2,1.3z M175.1,182.2c20.3,0.3,40.4,5.2,58.5,14.5
	c0.3,0.2,0.6,0.5,0.8,0.8c0.5,0.7,0.6,1.6,0.5,2.3c0.3-1.3-0.2-2.7-1.4-3.4C215.4,187.3,195.4,182.4,175.1,182.2z M175.1,182.2
	c-0.6,0-1.2,0-1.8,0C173.9,182.2,174.5,182.2,175.1,182.2z M160.5,214.9c-11.3-1.5-22.1-5.8-31.3-12.4v12.6
	c7.2,14.8,22.4,25.3,39.2,30.7c1.1,0.5,2.5-0.1,2.9-1.2c0.2-0.4,0.2-0.8,0.2-1.2c-1.8-10.1-5.6-19.7-11-28.4
	c25.2,3.1,50.7-1.5,73.2-13.1c0.7-0.5,1.2-1.2,1.3-2c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.4-0.6,0.7-0.9,1h0
	C211.1,213.4,185.6,217.9,160.5,214.9z M129.1,215.1c-2.3-4.8-3.8-10.1-4.2-15.8c0,0,0,0,0,0c-0.4,5.7-1.9,11-4.2,15.8v30.8
	c0,2.3,1.9,4.2,4.2,4.2c0,0,0,0,0,0c2.3,0,4.2-1.9,4.2-4.2V215.1z M128.3,196.6c-1.1,0.8-2.2,1.6-3.2,2.4c0,0.1-0.1,0.1-0.1,0.2
	c1.4,1.2,2.8,2.3,4.2,3.3v-3.3C129.1,198.2,128.8,197.3,128.3,196.6z M247.8,91.6c0.5-1.1,0-2.4-1.1-2.8c-0.5-0.2-1-0.2-1.4-0.1
	c-36.8,12-76.7,35.9-100.5,68.3h-0.2c13.3-50,2.9-109.5-17.5-155.7c-0.3-0.7-1-1.2-1.7-1.2c0.1,0,0.3,0.1,0.4,0.1
	c0.5,0.2,0.9,0.6,1.1,1.1c14.8,34,22.8,70.5,23.5,107.6c0,38.5-10.7,69.2-22.9,86.9c0.3,0.2,0.6,0.5,0.8,0.8
	c12.6-9,27.7-14.1,43.2-14.4c0.6,0,1.2,0,1.8,0c-0.6,0-1.2,0-1.8,0C205,162,232.2,125.7,247.8,91.6z M124.8,199.1L124.8,199.1
	L124.8,199.1c0.1,0,0.2,0,0.2,0c0-0.1,0.1-0.1,0.1-0.2c0.8-1,1.5-2,2.2-3.1c-0.7-0.5-1.6-0.8-2.5-0.8c-0.9,0-1.7,0.3-2.3,0.7
	c0.8,1.1,1.6,2.2,2.4,3.2C125,198.8,124.9,198.9,124.8,199.1z M124.9,199.1L124.9,199.1C124.9,199.2,124.9,199.2,124.9,199.1
	c0.1,0,0.1-0.1,0.2-0.1C125,199.1,125,199.1,124.9,199.1z M124.9,199.2C124.9,199.2,124.9,199.2,124.9,199.2
	C124.9,199.2,124.9,199.2,124.9,199.2C124.9,199.2,124.9,199.2,124.9,199.2z M124.9,199.2C124.9,199.2,124.9,199.2,124.9,199.2
	C124.9,199.2,124.9,199.2,124.9,199.2C124.9,199.2,124.9,199.2,124.9,199.2z M124.7,199.2C124.7,199.2,124.7,199.2,124.7,199.2
	c-0.7,1-2.7,3.7-4.1,5.5v0c0.9-1.1,2.2-2.9,4.2-5.5c0,0,0-0.1,0-0.1v0h0C124.8,199.2,124.8,199.2,124.7,199.2z M124.7,199.2
	c0,0,0.1-0.1,0.2-0.1l0-0.1C124.8,199.1,124.8,199.2,124.7,199.2z M120.7,199.1v3.3c1.4-1,2.7-2,3.9-3c0-0.1,0.1-0.1,0.1-0.1
	c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2l-0.2-0.3c-0.9-0.9-1.9-1.7-2.9-2.5C121.1,197,120.7,198,120.7,199.1z M105.5,157L105.5,157
	C81.8,124.9,42,100.8,4.8,88.7c-1.1-0.3-2.3,0.3-2.7,1.5c-0.1,0.5-0.1,1,0.1,1.4c15.2,33.8,42.1,70.1,75.9,90.4
	c18.2,0.7,33.4,6,43.7,14.2c0.2-0.3,0.5-0.5,0.8-0.7C115.9,186.1,109.8,173,105.5,157z M120.7,204.7v-2.3
	c-9.3,6.6-20,10.8-31.3,12.4c0,0-0.1,0.1-0.4,0.2c0.1,0,0.3,0,0.4,0c-5.4,8.7-9.2,18.3-11,28.4c-0.2,1.2,0.7,2.4,1.9,2.5
	c0.4,0.1,0.8,0,1.2-0.2c16.8-5.3,32.1-15.8,39.3-30.6V204.7c-0.7,0.9-1.1,1.4-1.2,1.6C119.6,206.1,120.1,205.5,120.7,204.7z
	 M125.3,0c-1-0.2-1.9,0.3-2.3,1.2c-10.8,24.9-18,51.1-21.4,78h0C105,51.9,112.8,25,123.2,1.3c0.2-0.5,0.6-0.9,1.1-1.1
	C124.6,0.1,125,0,125.3,0z M101.6,79.2c-1.2,9.8-2,19.7-2.1,29.6c0,17.9,2.3,34.1,6.1,48.1h0.2C99.1,132.5,98.3,105.7,101.6,79.2
	L101.6,79.2z M62.8,215.3c14.2,1.5,24,0.2,26.1-0.4C80.2,216,71.5,216.1,62.8,215.3z M15.3,197.3c-0.1,0.2-0.2,0.4-0.3,0.6
	c0.2-0.5,0.6-1,1.1-1.3c19.3-9.7,40.6-14.7,62.2-14.4c-0.1,0-0.1-0.1-0.2-0.1c-18.4-0.7-39.8,3.5-62,14.4
	C15.8,196.7,15.5,196.9,15.3,197.3z M15,197.9c-0.4,1-0.3,2.1,0.3,3c0.2,0.3,0.5,0.6,0.9,0.8c14.6,7.5,30.5,12.1,46.6,13.5
	c-12.8-1.4-29.1-5-46.6-13.6C14.9,200.8,14.5,199.3,15,197.9z`;
const dc_flag = `M250,143.5c-0.7,0.1-1.4,0.3-2.2,0.3c-81.9,0-163.8,0-245.7,0c-0.7,0-1.3,0-2.1,0
	c0-16.9,0-33.6,0-50.4c0.9,0,1.7,0,2.5,0c82.2,0,164.5,0,246.7,0c0.3,0,0.5,0,0.8-0.1C250,110.1,250,126.8,250,143.5z M250,168.6
	c-0.8-0.1-1.6-0.2-2.4-0.2c-81.7,0-163.4,0-245.2,0c-0.8,0-1.6,0-2.4,0c0,17,0,33.6,0,50.4c1,0,1.9,0,2.8,0c71.5,0,143.1,0,214.6,0
	c10.9,0,21.7,0,32.6,0C250,202.1,250,185.4,250,168.6z M214.7,75.7c-1.7-5-3.3-10.1-5-15.1c-0.4-1.1-0.3-1.8,0.8-2.5
	c3.8-2.6,7.6-5.4,11.3-8.1c0.5-0.4,1.1-0.8,2-1.5c-5.9,0-11.1,0-16.4,0c-1.2,0-1.8-0.3-2.1-1.5c-1.4-4.5-2.8-9-4.3-13.5
	c-0.2-0.6-0.4-1.1-0.8-2.3c-1.9,5.7-3.5,10.7-5.1,15.8c-0.4,1.2-0.9,1.5-2.1,1.5c-4.8-0.1-9.6,0-14.4,0c-0.5,0-0.9,0.1-1.8,0.2
	c4.5,3.3,8.6,6.3,12.8,9.3c1.2,0.8,1.5,1.5,1,3c-1.3,3.7-2.5,7.5-3.7,11.3c-0.4,1.2-0.7,2.3-1.3,4c5.2-3.7,9.9-7.1,14.6-10.5
	c4.8,3.4,9.5,6.8,14.2,10.2C214.5,75.9,214.6,75.8,214.7,75.7z M139.3,75.7c-1.4-4.3-2.8-8.5-4.2-12.8c-1.2-3.8-1.3-3.8,2-6.2
	c3.6-2.6,7.3-5.3,10.9-7.9c0-0.1-0.1-0.2-0.1-0.4c-0.5,0-1,0-1.5,0c-4.7,0-9.4,0-14.2,0c-1.3,0-1.9-0.3-2.3-1.6
	c-0.6-2.4-1.5-4.8-2.2-7.1c-0.9-2.6-1.7-5.3-2.8-8.5c-0.4,1.2-0.7,1.8-0.9,2.4c-1.4,4.4-2.9,8.8-4.3,13.3c-0.4,1.3-1,1.7-2.3,1.6
	c-3.7-0.1-7.4,0-11.1,0c-1.5,0-3,0-4.5,0c3.1,2.7,6.2,5,9.3,7.3c1.5,1.1,3.7,2,4.1,3.4c0.5,1.5-0.7,3.5-1.3,5.3
	c-1.2,3.8-2.4,7.5-3.8,11.8c5.2-3.8,9.9-7.2,14.6-10.6c4.8,3.5,9.4,6.8,14.1,10.2C139.1,75.8,139.2,75.7,139.3,75.7z M49.9,65.7
	c4.8,3.5,9.5,6.8,14.1,10.2c-1-4.4-2.3-8.4-3.7-12.5c-1.4-4.4-1.4-4.3,2.3-7.1c3.5-2.5,6.9-5,10.9-7.9c-5.9,0-11.1-0.1-16.3,0
	c-1.4,0-2-0.4-2.4-1.8c-1.2-4.2-2.6-8.3-3.9-12.5c-0.3-0.9-0.6-1.7-1-3c-2,6.1-3.8,11.6-5.7,17.4c-5.8,0-11.5,0-17.8,0
	c0.9,0.7,1.4,1.1,1.9,1.5c3.6,2.7,7.3,5.4,11,7.9c1.2,0.8,1.4,1.6,1,3c-1.7,4.9-3.2,9.8-5,15.3C40.5,72.5,45.2,69.1,49.9,65.7z`;
const dc_monument = `M79,220.3c0,8.1,0,15.7,0,23.4c2.5,0.9,3.9,0.4,3.7-2.4c-0.1-0.9,0-1.9,0-2.8
	c0-7.8,0-7.8,7.9-9c0-4.6-0.2-9.2,0.1-13.9c0.1-1.6,1.2-3.2,1.8-4.8c1.4,0.7,2.7,1.5,4.1,2.2c1.8,0.8,3.6,1.8,5.4,2.3
	c2,0.6,4,1.5,3,3.7c-0.5,1.2-2.8,1.8-4.4,2.1c-1,0.2-2.3-0.5-3.8-0.9c0,3.1,0,6.1,0,9.4c3.5,0,6.9,0,11.1,0c0-3.3,0-6.5,0-9.6
	c1.1-65.3,2.2-130.6,3.4-196c0-2,0.7-4.2,1.7-6c2.9-5.2,6.2-10.3,9.4-15.4c2.2-3.6,3.2-3.5,5.5,0.2c0.2,0.3,0.4,0.7,0.6,1
	c8.7,10.7,10.9,22.9,10.9,36.5c0.1,44.2,1.2,88.3,2,132.5c0.4,22.5,0.8,45,1.2,67.5c0,1.1,0.1,2.1,0.2,3.4c3.4,0,6.7,0,10.5,0
	c0.1-1.3,0.3-2.7,0.3-4.1c0-8.2-0.1-16.3,0.1-24.5c0-1.5,1.2-2.9,1.9-4.4c1.3,0.7,2.5,1.4,3.8,2c1.9,0.9,3.9,1.5,5.6,2.7
	c1.3,0.8,2.1,2.2,3.2,3.3c-1.5,0.8-2.8,2-4.4,2.4c-1.2,0.3-2.6-0.5-4.1-0.8c0,7.8,0,15.5,0,23.4c3.7,0,7.3,0,11.3,0
	c0.1-1.4,0.3-2.8,0.3-4.2c0-8.2-0.1-16.3,0.1-24.5c0-1.5,1.3-2.9,2-4.3c1.3,0.7,2.5,1.4,3.8,2.1c1.7,0.9,3.7,1.5,5.3,2.6
	c1.3,0.9,2.4,2.3,3.5,3.4c-1.6,0.8-3.1,1.9-4.8,2.3c-1,0.3-2.3-0.5-3.7-0.8c0,7.9,0,15.5,0,23.5c3.7,0,7.3,0,11.3,0
	c0.1-1.7,0.3-3.5,0.3-5.3c0-7.8-0.1-15.5,0.1-23.3c0-1.5,1.2-2.9,1.9-4.4c1.3,0.7,2.5,1.4,3.8,2c1.6,0.8,3.2,2,4.9,2.4
	c2.3,0.6,4.7,1.2,3.6,3.9c-0.5,1.2-3.4,1.5-5.3,1.9c-0.8,0.2-1.8-0.4-2.9-0.7c0,7.9,0,15.6,0,23.5c3.8,0,7.5,0,11.6,0
	c0-8.2,0-16.2,0-24.2c0-2-0.2-4,0.1-6c0.2-1,1-2.4,1.8-2.7c0.9-0.3,2.2,0.3,3.2,0.9c2.1,1.1,4.3,2.3,6.2,3.7
	c1.2,0.9,2.2,2.2,3.2,3.3c-1.4,0.9-2.8,2.1-4.4,2.4c-1.1,0.3-2.6-0.5-4.1-0.9c0,7.8,0,15.5,0,23.4c3.7,0,7.4,0,11.6,0
	c0-4.3,0-8.6,0-12.8c0-5.6-0.1-11.2,0.1-16.9c0-1.1,0.9-2.6,1.9-3.1c0.7-0.4,2.2,0.3,3.1,0.8c2.1,1.1,4.3,2.3,6.3,3.7
	c1.2,0.9,2.2,2.2,3.2,3.3c-1.4,0.9-2.8,2.1-4.4,2.5c-1.1,0.3-2.6-0.5-4.1-0.9c0,7.8,0,15.5,0,23.7c4.8,0,9.6,0,14.3,0
	c2.1,0,4.7-0.4,4.8,2.7c0.1,3.4-2.7,3-5,3c-51.7,0-103.4,0-155,0c-27.4,0-54.9,0-82.3,0c-1.3,0-2.8,0.3-4-0.1
	c-1.2-0.5-2.1-1.9-3.1-2.9c1.1-0.9,2.1-2.6,3.2-2.6c5.2-0.3,10.4-0.1,16.3-0.1c0-2.2,0-4.2,0-6.1c0-7.8-0.1-15.5,0.1-23.3
	c0-1.3,1-3,2.1-3.8c0.5-0.4,2.3,1,3.4,1.6c1.8,0.9,3.6,2.2,5.5,2.8c2,0.6,4.1,1.3,3.2,3.5c-0.5,1.2-2.9,1.9-4.6,2.4
	c-1,0.3-2.3-0.5-3.7-0.8c0,7.9,0,15.6,0,23.5c3.7,0,7.3,0,11.4,0c0.1-1.3,0.2-2.7,0.2-4.1c0-8.2-0.1-16.3,0.1-24.5
	c0-1.5,1.2-2.9,1.9-4.4c1.3,0.7,2.5,1.4,3.8,2c1.9,0.9,3.9,1.6,5.6,2.7c1.2,0.8,2.1,2.2,3.1,3.4c-1.4,0.8-2.8,2-4.3,2.4
	c-1.1,0.3-2.5-0.5-4.1-0.8c0,7.9,0,15.5,0,23.5c3.8,0,7.3,0,11.4,0c0.1-1.3,0.2-2.7,0.2-4.1c0-8.2-0.1-16.3,0.1-24.5
	c0-1.5,1.3-2.9,2-4.4c1.3,0.7,2.5,1.4,3.8,2c1.9,0.9,3.9,1.6,5.7,2.7c1.2,0.8,2.1,2.2,3.1,3.3c-1.4,0.8-2.8,2-4.4,2.4
	c-1.1,0.3-2.5-0.5-4.1-0.9c0,7.9,0,15.6,0,23.5c3.8,0,7.3,0,11.6,0c0-1.7,0-3.4,0-5c0-8-0.1-16.1,0.1-24.1c0-1.3,1-3,2.1-3.8
	c0.5-0.4,2.3,1,3.5,1.6c1.9,0.9,3.6,2.1,5.6,2.8c2,0.6,4.1,1.4,3.1,3.6c-0.5,1.2-3,1.8-4.7,2.3C81.7,221.4,80.6,220.7,79,220.3z
	 M136.7,244c-1.2-72.5-2.5-144.7-3.7-217c-1.9,0-3.3,0-4.6,0c0,72.5,0,144.7,0,217C131.2,244,133.6,244,136.7,244z M121.8,26.9
	c-1.6,0-3,0-4.7,0c-1.2,67.8-2.3,135.3-3.5,202.7c3.2,0,5.7,0,8.2,0C121.8,161.9,121.8,94.5,121.8,26.9z M100.6,243.5
	c7.4,0,14.3,0,21.1,0c0-2.7,0-5,0-7.2c-7.2,0-14.1,0-21.1,0C100.6,238.9,100.6,241.1,100.6,243.5z M94.3,236.4c-1.7,0-3.6,0-5.4,0
	c0,2.5,0,4.7,0,7.2c1.9,0,3.5,0,5.4,0C94.3,241.2,94.3,239,94.3,236.4z`;

export const initMorphAnimation = () => {
  let tl = gsap.timeline({ repeat: -1 });
  const animeDuration = 3.2;
  const animeDelay = 5;
  tl.to("#morph-path", {
    duration: animeDuration,
    delay: animeDelay,
    morphSVG: dc_flag,
  })
    .to("#morph-path", {
      duration: animeDuration,
      delay: animeDelay,
      morphSVG: dc_monument,
    })
    .to("#morph-path", {
      duration: animeDuration,
      delay: animeDelay,
      morphSVG: cannabis,
    });
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRoutes } from "react-router-dom";
import routes from "@/routes";
import "@/styles/index.css";
import Header from "@/core/template/Header/index";
import Footer from "@/core/template/Footer";
import AgeVerification from "@/core/AgeVerification";
const App = () => {
    const routing = useRoutes(routes);
    return (_jsxs("div", { className: "flex flex-col min-h-screen bg-primary", children: [_jsx(AgeVerification, {}), _jsx(Header, {}), _jsx("main", { className: "flex-grow", children: routing }), _jsx(Footer, {})] }));
};
export default App;

import { BUSINESS_NAME } from "@/core/constants/business";
import episodes from "./episodes/episodes.json";
export const generatePodcastRichSnippet = () => {
    // Create the main podcast schema
    const podcastSchema = {
        "@context": "https://schema.org",
        "@type": "PodcastSeries",
        name: "Street Lawyer Podcast",
        description: "A podcast exploring cannabis, culture, law, and street life with Lonny the Street Lawyer - Stanford and Columbia Law graduate, cannabis pioneer, and criminal defense attorney.",
        url: "https://slsdispensary.com/podcast",
        image: "https://slsdispensary.com/images/podcast/lonny-the-street-lawyer.jpg",
        author: {
            "@type": "Person",
            name: "Lonny the Street Lawyer",
            description: "Criminal defense trial attorney, radio personality, and Cannabis pioneer. Graduate of Stanford University and Columbia Law School.",
            url: "https://slsdispensary.com/about",
        },
        publisher: {
            "@type": "Organization",
            name: BUSINESS_NAME,
            logo: {
                "@type": "ImageObject",
                url: "https://slsdispensary.com/images/sls-logo.png",
            },
        },
        keywords: "Cannabis, Law, Culture, Criminal Defense, Street Life, Marijuana",
        inLanguage: "en-US",
    };
    // Create individual episode schemas (limit to 10 most recent for performance)
    const episodeSchemas = episodes.slice(0, 10).map((episode) => {
        return {
            "@context": "https://schema.org",
            "@type": "PodcastEpisode",
            episodeNumber: episodes.findIndex((ep) => ep.slug === episode.slug) + 1,
            name: episode.title,
            url: `https://slsdispensary.com/podcast/${episode.slug}`,
            description: episode.description,
            timeRequired: episode.duration,
            associatedMedia: {
                "@type": "AudioObject",
                contentUrl: `https://slsdispensary.com/${episode.mp3}`,
                encodingFormat: "audio/mpeg",
            },
            partOfSeries: {
                "@type": "PodcastSeries",
                name: "Street Lawyer Podcast",
            },
            author: {
                "@type": "Person",
                name: "Lonny the Street Lawyer",
            },
        };
    });
    // Create organization schema for Lonny's brand
    const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Street Lawyer Services",
        url: "https://slsdispensary.com",
        logo: "https://slsdispensary.com/images/sls-logo.png",
        sameAs: [
            "https://www.instagram.com/slsdispensary/",
            "https://www.instagram.com/slsdispensarydc/",
            "https://www.facebook.com/profile.php?id=100083761881964",
            "https://x.com/DCStreetLawyer",
        ],
        founder: {
            "@type": "Person",
            name: "Lonny the Street Lawyer",
        },
    };
    return {
        podcast: JSON.stringify(podcastSchema),
        episodes: episodeSchemas.map((episode) => JSON.stringify(episode)),
        organization: JSON.stringify(organizationSchema),
    };
};

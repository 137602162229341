var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext, useEffect, } from "react";
import { BASE_API_URL, ENDPOINTS } from "@/core/api/endpoints";
const AuthContext = createContext(undefined);
const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
export const AuthProvider = ({ children, }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const storedAuthState = localStorage.getItem("isAuthenticated");
        return storedAuthState === "true";
    });
    const [userRole, setUserRole] = useState(null);
    useEffect(() => {
        const handleActivity = () => {
            localStorage.setItem("lastActivity", Date.now().toString());
        };
        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keydown", handleActivity);
        const checkInactivity = () => {
            const lastActivity = parseInt(localStorage.getItem("lastActivity") || "0", 10);
            if (Date.now() - lastActivity > INACTIVITY_TIMEOUT) {
                logout();
            }
        };
        const interval = setInterval(checkInactivity, 1000);
        return () => {
            clearInterval(interval);
            window.removeEventListener("mousemove", handleActivity);
            window.removeEventListener("keydown", handleActivity);
        };
    }, []);
    const login = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch(`${BASE_API_URL}${ENDPOINTS.LOGIN}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });
            const responseBody = yield response.json();
            const responseData = JSON.parse(responseBody.body);
            if (response.ok) {
                setIsAuthenticated(true);
                localStorage.setItem("isAuthenticated", "true");
                localStorage.setItem("userEmail", email); // Store the email in local storage
                const isEmployee = responseData.isEmployee;
                setUserRole(isEmployee ? "employee" : "customer");
                localStorage.setItem("lastActivity", Date.now().toString());
            }
            else {
                throw new Error((responseBody === null || responseBody === void 0 ? void 0 : responseBody.message) || "Login failed");
            }
        }
        catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    });
    const logout = () => {
        setIsAuthenticated(false);
        setUserRole(null);
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("userEmail"); // Remove the email from local storage
        localStorage.removeItem("lastActivity");
    };
    return (_jsx(AuthContext.Provider, { value: { isAuthenticated, userRole, login, logout }, children: children }));
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

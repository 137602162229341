/**
 * src/core/utils/ageVerification.ts
 * Checks if the user is at least the specified age.
 * @param birthYear - The year of birth.
 * @param birthMonth - The month of birth (1-12).
 * @param birthDay - The day of birth (1-31).
 * @param minAge - The minimum age required (default is 21).
 * @returns True if the user is at least the specified age, otherwise false.
 */
export const isAgeVerified = (birthYear, birthMonth, birthDay, minAge = 21) => {
    const today = new Date();
    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)) {
        return age >= minAge;
    }
    else {
        return age - 1 >= minAge;
    }
};

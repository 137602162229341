import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import mediaData from "./media.json";
const platformIcons = {
    Instagram: "fa-brands fa-instagram",
    Twitter: "fa-brands fa-twitter",
    LinkedIn: "fa-brands fa-linkedin",
    Facebook: "fa-brands fa-facebook-f",
    YouTube: "fa-brands fa-youtube",
    "Apple Music": "fa-brands fa-apple",
    "Apple Podcasts": "fa-brands fa-apple",
    Spotify: "fa-brands fa-spotify",
    SoundCloud: "fa-brands fa-soundcloud",
    Threads: "fa-brands fa-threads",
    Music: "fa-solid fa-music",
    Podcast: "fa-solid fa-podcast",
    IMDB: "fa-brands fa-imdb",
    Magazine: "fa-regular fa-newspaper",
    "News Article": "fa-regular fa-newspaper",
    News: "fa-regular fa-newspaper",
    Directory: "fa-solid fa-list",
    "Legal Directory": "fa-solid fa-scale-balanced",
    Government: "fa-solid fa-landmark",
    Spreaker: "fa-solid fa-microphone",
    "iHeart Radio": "fa-solid fa-heart",
    Yelp: "fa-brands fa-yelp",
    Retail: "fa-solid fa-shopping-bag",
    Video: "fa-solid fa-video",
};
const MediaList = () => {
    const [filter, setFilter] = useState("All");
    const uniquePlatforms = useMemo(() => {
        const platforms = mediaData.map((item) => item.platform);
        return ["All", ...Array.from(new Set(platforms)).sort()];
    }, []);
    const filteredMedia = useMemo(() => {
        if (filter === "All") {
            return mediaData;
        }
        return mediaData.filter((item) => item.platform === filter);
    }, [filter]);
    return (_jsxs("div", { className: "w-full max-w-6xl mx-auto", children: [_jsxs("div", { className: "sticky top-0 z-10 flex flex-wrap items-center gap-2 p-4 mb-6 bg-white rounded-lg shadow-lg bg-opacity-60", children: [_jsx("span", { className: "mr-2 font-semibold text-primary", children: "Filter by platform:" }), uniquePlatforms.map((platform) => (_jsx("button", { onClick: () => setFilter(platform), className: `px-3 py-1 text-sm rounded-full transition-all ${filter === platform
                            ? "bg-gradient-to-r from-green-400 to-blue-500 text-white font-medium"
                            : "gradient-anime-sea-blue text-gray-300 hover:bg-gray-600"}`, children: platform }, platform)))] }), _jsx("div", { className: "grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3", children: filteredMedia.map((item, index) => (_jsxs("a", { href: item.url, target: "_blank", rel: "noopener noreferrer", className: "flex flex-col h-full p-4 transition-transform duration-200 bg-gray-800 bg-opacity-50 border border-gray-700 rounded-lg shadow-lg hover:scale-105 hover:border-green-500", children: [_jsxs("div", { className: "flex items-center mb-3", children: [_jsx("div", { className: "flex items-center justify-center w-10 h-10 mr-3 text-lg rounded-full bg-gradient-to-r from-green-500 to-blue-500", children: _jsx("i", { className: platformIcons[item.platform] || "fa-solid fa-link" }) }), _jsx("div", { className: "text-sm font-medium text-green-300", children: item.platform })] }), _jsx("h3", { className: "mb-2 text-lg font-bold text-white", children: item.title }), _jsxs("div", { className: "flex items-center mt-auto text-xs text-gray-400", children: [_jsx("span", { className: "truncate", children: item.url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "") }), _jsx("i", { className: "ml-1 fa-solid fa-arrow-up-right-from-square" })] })] }, index))) }), filteredMedia.length === 0 && (_jsx("div", { className: "p-8 text-center text-gray-400 bg-gray-800 rounded-lg bg-opacity-30", children: "No media items found for this platform." })), _jsx("div", { className: "mt-8 text-center text-gray-100", children: _jsxs("p", { children: ["Total: ", filteredMedia.length, " item", filteredMedia.length !== 1 ? "s" : ""] }) })] }));
};
export default MediaList;

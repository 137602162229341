var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => (Object.assign(Object.assign({}, prevData), { [name]: value })));
    };
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setLoading(true);
        try {
            // Simulate form submission
            console.log("Form submitted:", formData);
            setTimeout(() => {
                setLoading(false);
                alert("Form submitted successfully!");
                setFormData({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    message: "",
                });
            }, 1000);
        }
        catch (error) {
            console.error("Form submission error:", error);
            setLoading(false);
        }
    });
    return (_jsx("section", { className: "flex flex-col items-center overflow-x-hidden", children: _jsx("form", { id: "contact-form", className: "w-full space-y-8", onSubmit: handleSubmit, children: _jsxs("div", { className: "py-6 rounded-lg shadow-lg gradient-anime-velvet-purple", children: [_jsx("h2", { className: "mb-6 text-4xl text-center lato-bold boujee-text", children: "Contact Us" }), _jsxs("div", { className: "max-w-md mx-auto space-y-4 text-primary", children: [_jsx("input", { type: "text", name: "firstName", placeholder: "First Name", className: "w-full p-2 border border-gray-300 rounded", value: formData.firstName, onChange: handleChange }), _jsx("input", { type: "text", name: "lastName", placeholder: "Last Name", className: "w-full p-2 border border-gray-300 rounded", value: formData.lastName, onChange: handleChange }), _jsx("input", { type: "tel", name: "phone", placeholder: "Phone", className: "w-full p-2 border border-gray-300 rounded", value: formData.phone, onChange: handleChange }), _jsx("input", { type: "email", name: "email", placeholder: "Email", className: "w-full p-2 border border-gray-300 rounded", value: formData.email, onChange: handleChange }), _jsx("textarea", { name: "message", placeholder: "Your Message", className: "w-full p-2 border border-gray-300 rounded", value: formData.message, onChange: handleChange }), _jsx("button", { type: "submit", disabled: loading, className: "w-full px-2 py-2.5 font-bold text-blue-800 border border-blue-300 rounded gradient-anime-weed-bg", children: loading ? "Sending..." : "Send Message" })] })] }) }) }));
};
export default ContactForm;

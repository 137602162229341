export const LOCATIONS = [
    {
        name: "Washington DC",
        address1: "3400 Connecticut Avenue NW",
        address2: "",
        city: "Washington",
        state: "DC",
        zip: "20008",
        phoneNumber: "2028990790",
        email: "",
        googleMapsUrl: "https://maps.app.goo.gl/xWKmhkE8Fboa9qqk9",
        appleMapsUrl: "https://maps.apple.com/?address=409%20H%20St%20NE,%20Washington,%20DC%20%2020002,%20United%20States&auid=17228111914756883022&ll=38.899956,-77.000144&lsp=9902&q=SLS%20Weed%20Dispensary%20DC",
        wazeUrl: "https://waze.com/ul?q=Street%20Lawyer%20Services%20409%20H%20St%20NE,%20Washington,%20DC%2020002",
        shopURL: "washington-dc",
        imageJpgUrl: "/images/dispensary/sls-weed-dispensary-storefront-in-washington-dc.jpg",
        imageWebpUrl: "/images/dispensary/sls-weed-dispensary-storefront-in-washington-dc.webp",
    },
    {
        name: "Baltimore County",
        address1: "101 E Chesapeake Avenue",
        address2: "Suite 102",
        city: "Towson",
        state: "MD",
        zip: "21286",
        phoneNumber: "4436530028",
        email: "",
        googleMapsUrl: "https://maps.app.goo.gl/fudVxKF8HhAZfR1A8",
        appleMapsUrl: "https://maps.apple.com/?q=Street+Lawyer+Services&address=101+E+Chesapeake+Ave+Suite+102,+Towson,+MD+21286,+United+States",
        wazeUrl: "https://www.waze.com/ul?q=Street%20Lawyer%20Services%20101%20E%20Chesapeake%20Ave%20Suite%20102%2C%20Towson%2C%20MD%2021286",
        shopURL: "towson-md",
        imageJpgUrl: "/images/dispensary/sls-weed-dispensary-storefront-in-baltimore-maryland.jpg",
        imageWebpUrl: "/images/dispensary/sls-weed-dispensary-storefront-in-baltimore-maryland.webp",
    },
];

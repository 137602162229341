import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { horizontalLoop } from "@/core/utils/horizontal-helper-gsap";
const Slider = () => {
    const images = [
        "/images/dispensary/interior-of-sls-dispensary-baltimore.jpg",
        "/images/dispensary/cali-gold-thc-infused-gummies.jpg",
        "/images/dispensary/woman-at-sls-event-1.jpg",
        "/images/website/street-lawyer-services-weed-dispensary-dc-14.jpg",
        "/images/dispensary/sls-event-selling-apparal.jpg",
        "/images/dispensary/sls-podcast-lonny-the-street-lawyer-2.jpg",
        "/images/gallery/street-lawyer-services-weed-dispensary-dc-101.jpg",
        "/images/dispensary/exterior-of-sls-dispensary-in-washington-dc.jpg",
        "/images/dispensary/420-buny-sls-dispensary-model.jpg",
        "/images/dispensary/natalie-nunn-baddies-weed-strain.jpg",
        "/images/dispensary/woman-at-sls-event-2.jpg",
        "/images/website/street-lawyer-services-weed-dispensary-dc-40.jpg",
    ];
    useEffect(() => {
        const galleryContainer = document.querySelector(".gallery-container");
        const galleryItems = galleryContainer
            ? galleryContainer.querySelectorAll(".gallery-img")
            : [];
        if (galleryItems.length) {
            horizontalLoop(galleryItems, { speed: 0.3, repeat: -1, paused: false });
        }
    }, []);
    useEffect(() => {
        const handleResize = () => {
            const galleryContainer = document.querySelector(".gallery-container");
            const galleryItems = galleryContainer
                ? galleryContainer.querySelectorAll(".gallery-img")
                : [];
            if (galleryItems.length) {
                horizontalLoop(galleryItems, { speed: 0.3, repeat: -1, paused: false });
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize(); // Initial call to handle the current screen size
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsx("section", { children: _jsx("div", { className: "overflow-x-hidden gallery-container", children: images.map((src, index) => (_jsx("img", { className: "gallery-img", src: src, alt: "" }, index))) }) }));
};
export default Slider;

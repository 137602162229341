import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Helmet } from "react-helmet";
import faqs from "./faqs.json";
import { META } from "./meta";
const FAQPage = () => {
    // Get current date in ISO format for the lastReviewed property
    const currentDate = new Date().toISOString().split("T")[0];
    // Generate enhanced JSON-LD schema for FAQs
    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        name: "SLS Dispensary Frequently Asked Questions",
        description: META.meta_description,
        url: `https://slsdispensary.com${META.canonicalUrl}`,
        lastReviewed: currentDate,
        mainEntity: faqs.map((faq) => ({
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
                "@type": "Answer",
                text: faq.answer,
            },
        })),
        // Optional but recommended for enhanced SEO
        mainContentOfPage: {
            "@type": "WebPageElement",
            cssSelector: ".faq-container",
        },
        speakable: {
            "@type": "SpeakableSpecification",
            cssSelector: ["h1", ".faq-question", ".faq-answer"],
        },
        datePublished: "2023-07-01", // Replace with actual date when FAQs were first published
        dateModified: currentDate,
        author: {
            "@type": "Organization",
            name: "SLS Dispensary",
            url: "https://slsdispensary.com",
        },
        publisher: {
            "@type": "Organization",
            name: "SLS Dispensary",
            logo: {
                "@type": "ImageObject",
                url: "https://slsdispensary.com/images/sls-logo.png",
            },
        },
    };
    return (_jsxs("div", { className: "gradient-anime-velvet-purple", children: [_jsxs(Helmet, { children: [_jsx("title", { children: META.title }), _jsx("meta", { name: "description", content: META.meta_description }), _jsx("meta", { name: "keywords", content: META.keywords }), _jsx("link", { rel: "canonical", href: `https://slsdispensary.com${META.canonicalUrl}` }), _jsx("meta", { property: "og:title", content: META.title }), _jsx("meta", { property: "og:description", content: META.meta_description }), _jsx("meta", { property: "og:type", content: "website" }), _jsx("meta", { property: "og:url", content: `https://slsdispensary.com${META.canonicalUrl}` }), _jsx("meta", { property: "og:image", content: "https://slsdispensary.com/images/dispensary/sls-dispensary-model.jpg" }), _jsx("meta", { property: "og:site_name", content: "SLS Dispensary" }), _jsx("script", { type: "application/ld+json", children: JSON.stringify(faqSchema) })] }), _jsx("section", { className: "flex flex-col items-center overflow-x-hidden", children: _jsxs("div", { className: "flex flex-col items-center justify-center w-11/12 max-w-3xl pt-8 pb-12 mx-auto faq-container md:pt-10", children: [_jsx("h1", { className: "mb-10 text-4xl font-bold leading-snug tracking-wider text-center lg:text-5xl boujee-text helvetica-neue-outline", children: "Frequently Asked Questions (FAQs)" }), faqs.map((faq, index) => (_jsxs("div", { className: "p-6 mb-6 rounded-lg shadow-lg gradient-anime-sea-blue", children: [_jsx("h2", { className: "mb-4 text-2xl font-semibold text-white faq-question", children: faq.question }), _jsx("div", { className: "faq-answer", children: _jsx("p", { className: "text-lg text-white", children: faq.answer }) })] }, index)))] }) })] }));
};
export default FAQPage;

import { jsx as _jsx } from "react/jsx-runtime";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const containerStyle = {
    width: "100%",
    height: "325px",
};
const center = {
    lat: 39.4015, // Replace with the latitude of your location
    lng: -76.6019, // Replace with the longitude of your location
};
const GoogleMapComponent = () => {
    return (_jsx(LoadScript, { googleMapsApiKey: "AIzaSyBFUIzxEwVOnNgLtjKbUi2sLhdw0EbdPjw", children: _jsx(GoogleMap, { mapContainerStyle: containerStyle, center: center, zoom: 15, children: _jsx(Marker, { position: center }) }) }));
};
export default GoogleMapComponent;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BUSINESS_HOURS } from "@/core/constants/business";
import { LOCATIONS } from "@/core/constants/locations";
import { formatTime } from "@/core/utils/formatTime";
import { FaPhone } from "react-icons/fa";
export const GoogleMapsDirection = ({ subject, city, state, }) => {
    // Find DC location object
    const dcLocation = LOCATIONS.find((loc) => loc.name === "Washington DC");
    if (!dcLocation) {
        return _jsx("div", { children: "Location not found" });
    }
    return (_jsx("section", { className: "flex flex-col items-center pt-2 pb-16 overflow-x-hidden text-white", children: _jsxs("div", { className: "grid items-start w-11/12 max-w-6xl grid-cols-1 gap-8 mx-auto justify-evenly md:grid-cols-2", children: [_jsxs("div", { className: "flex flex-col items-center text-lg font-medium text-center", children: [_jsx("a", { href: "/washington-dc", className: "text-3xl font-bold tracking-wide md:text-2xl boujee-text", children: "Washington DC" }), _jsxs("address", { className: "mt-2.5 md:mt-2 not-italic font-semibold", children: [dcLocation.address1, ", ", dcLocation.city, ", ", dcLocation.state, " ", dcLocation.zip] }), _jsx("div", { className: "mt-2 font-semibold", children: _jsxs("a", { className: "flex flex-row items-center", href: `tel:${dcLocation.phoneNumber}`, children: [_jsx(FaPhone, { className: "inline-block mr-2 text-sm" }), dcLocation.phoneNumber] }) }), _jsx("iframe", { title: "Directions to Washington DC", className: "mt-4 rounded-lg drop-shadow-md", frameBorder: "0", style: { border: 0, width: "100%", height: "300px" }, src: `https://www.google.com/maps/embed/v1/directions?key=AIzaSyBFUIzxEwVOnNgLtjKbUi2sLhdw0EbdPjw
              &destination=${encodeURIComponent(dcLocation.address1 +
                                " " +
                                dcLocation.city +
                                " " +
                                dcLocation.state +
                                " " +
                                dcLocation.zip)}
              &origin=${encodeURIComponent(city)},${state}
              &mode=driving`, allowFullScreen: true }), _jsx("a", { className: "mt-7 drop-shadow-md flex flex-row items-center gap-2 px-4 py-2.5 text-sm font-semibold leading-none tracking-wider rounded-full w-fit gradient-gold text-primary-800", href: "/washington-dc", children: "Visit SLS Washington DC" })] }), _jsxs("div", { className: "text-center", children: [_jsx("h2", { className: "mb-6 text-2xl font-bold boujee-text", children: "Hours of Operation" }), _jsx("ul", { className: "flex flex-col gap-2", children: Object.entries(BUSINESS_HOURS).map(([day, hours]) => (_jsxs("li", { className: "flex items-center justify-between px-4 py-2 font-bold text-left transition-colors rounded-lg hover:bg-white/5", children: [_jsxs("strong", { className: "min-w-[100px] tracking-wide", children: [day === "Monday" && "🌞 ", day === "Tuesday" && "🌞 ", day === "Wednesday" && "🌞 ", day === "Thursday" && "🌞 ", day === "Friday" && "🌞 ", day === "Saturday" && "🌞 ", day === "Sunday" && "🌞 ", day, ":"] }), _jsxs("span", { children: [formatTime(hours.open), " \u2013 ", formatTime(hours.close)] })] }, day))) })] })] }) }));
};

import { BUSINESS_NAME } from "@/core/constants/business";
export const generateAboutPageRichSnippet = () => {
    // Organization schema with focus on the "about" information
    const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: BUSINESS_NAME,
        alternateName: "SLS Dispensary",
        url: "https://slsdispensary.com/about",
        logo: "https://slsdispensary.com/images/sls-logo.png",
        description: "Street Lawyer Services is a marijuana dispensary with two locations in Washington DC and Baltimore Maryland. Founded in 2019 by CEO, Lonny the Street Lawyer, SLS Dispensary is known for quality cannabis strains, recreational weed, and exceptional service.",
        foundingDate: "2019",
        foundingLocation: {
            "@type": "Place",
            address: {
                "@type": "PostalAddress",
                addressLocality: "Washington",
                addressRegion: "DC",
                addressCountry: "US",
            },
        },
        founder: {
            "@type": "Person",
            name: "Lonny the Street Lawyer",
            sameAs: "https://www.instagram.com/lonnythestreetlawyer/",
        },
        sameAs: [
            "https://www.instagram.com/slsdispensary/",
            "https://www.instagram.com/slsdispensarydc/",
            "https://www.facebook.com/profile.php?id=100083761881964",
            "https://x.com/DCStreetLawyer",
        ],
    };
    // Person schema for Lonny the Street Lawyer
    const personSchema = {
        "@context": "https://schema.org",
        "@type": "Person",
        name: "Lonny the Street Lawyer",
        url: "https://slsdispensary.com/about",
        description: 'Lonny, better known as "Lonny the Street Lawyer," is a dynamic figure in both the legal and cannabis industries. A Miami native, Lonny\'s educational journey led him from Stanford University to Columbia Law School, becoming a high-profile criminal defense attorney and cannabis activist.',
        alumniOf: [
            {
                "@type": "CollegeOrUniversity",
                name: "Stanford University",
            },
            {
                "@type": "CollegeOrUniversity",
                name: "Columbia Law School",
            },
        ],
        sameAs: "https://www.instagram.com/lonnythestreetlawyer/",
        knowsAbout: ["Criminal Defense", "Cannabis Law", "Cannabis Industry"],
        worksFor: {
            "@type": "Organization",
            name: BUSINESS_NAME,
        },
    };
    // Get properly formatted ISO date strings with timezone
    const now = new Date();
    const formattedDateModified = now.toISOString(); // Full ISO format with timezone
    const formattedDatePublished = "2023-01-01T12:00:00Z"; // Full ISO format with timezone
    // Article schema for the About page content
    const articleSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        headline: "About SLS Dispensary | Our Story & Mission",
        description: "Learn about SLS Dispensary's journey to becoming a premier cannabis dispensary in Washington DC and Baltimore. Discover our commitment to quality products, exceptional customer service, and community education.",
        image: [
            "https://slsdispensary.com/images/website/street-lawyer-services-weed-dispensary-dc-75.jpg",
        ],
        author: {
            "@type": "Person",
            name: "Lonny the Street Lawyer",
            url: "https://slsdispensary.com/about",
        },
        publisher: {
            "@type": "Organization",
            name: BUSINESS_NAME,
            logo: {
                "@type": "ImageObject",
                url: "https://slsdispensary.com/images/sls-logo.png",
            },
        },
        datePublished: formattedDatePublished,
        dateModified: formattedDateModified,
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": "https://slsdispensary.com/about",
        },
        articleBody: "SLS Dispensary is a premier cannabis dispensary with locations in Washington DC and Baltimore, Maryland. Founded in 2019, we offer high-quality cannabis products and exceptional customer service.",
    };
    return {
        organization: JSON.stringify(organizationSchema),
        person: JSON.stringify(personSchema),
        article: JSON.stringify(articleSchema),
    };
};

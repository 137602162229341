import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/core/Cart/CartIcon.tsx
import { useEffect } from "react";
import { HiShoppingCart } from "react-icons/hi2";
import { useCart } from "@/core/context/CartContext";
const CartIcon = ({ toggleCartOverlay, iconColor, }) => {
    const { state } = useCart();
    useEffect(() => {
        // console.log("Cart state in CartIcon:", state);
    }, [state]);
    // Calculate the total quantity of items in the cart
    const totalItems = Array.isArray(state.items)
        ? state.items.reduce((total, item) => total + (item.quantity || 0), 0)
        : 0;
    return (_jsx("div", { className: "relative mt-[3px] h-8 w-8", children: _jsxs("button", { className: "relative flex items-center justify-center w-full h-full", onClick: () => {
                console.log("Cart state before toggling overlay:", state);
                toggleCartOverlay();
            }, children: [_jsx(HiShoppingCart, { className: `w-7 h-7 ${iconColor}` }), _jsx("span", { id: "cart-item-count", className: "absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-[10.025px] text-white bg-red-500 rounded-full pointer-events-none", children: totalItems })] }) }));
};
export default CartIcon;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/core/template/Header/index.tsx
import { useState } from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import MenuOverlay from "./MenuOverlay";
import CartOverlay from "../../Cart/CartOverlay";
const Header = () => {
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const toggleCartOverlay = () => {
        setIsCartOpen(!isCartOpen);
    };
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    return (_jsxs("div", { className: "font-semibold lato-bold text-[17.85px]", children: [_jsx(DesktopHeader, { toggleCartOverlay: toggleCartOverlay }), _jsx(MobileHeader, { toggleCartOverlay: toggleCartOverlay, toggleMobileMenu: toggleMobileMenu }), _jsx(MenuOverlay, { isOpen: isMobileMenuOpen, toggleMobileMenu: toggleMobileMenu }), _jsx(CartOverlay, { isOpen: isCartOpen, toggleCartOverlay: toggleCartOverlay })] }));
};
export default Header;

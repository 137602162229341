import { BUSINESS_NAME } from "@/core/constants/business";
/**
 * Generates meta information for Local SEO pages based on page data
 * @param subject - The subject of the page (e.g., "Weed Pickup")
 * @param city - The city targeted in the page (e.g., "Loch Raven")
 * @param state - The state abbreviation (e.g., "MD")
 * @param metaDescription - Custom meta description for the page
 * @param title - The title of the page
 */
export const generateLocalSEOMeta = (subject, city, state, metaDescription, title) => {
    const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    const canonicalPath = `/${subject.toLowerCase().replace(/\s+/g, "-")}/${city
        .toLowerCase()
        .replace(/\s+/g, "-")}-${state.toLowerCase()}`;
    return {
        title: `${formattedTitle} | ${BUSINESS_NAME}`,
        meta_description: metaDescription,
        canonicalUrl: canonicalPath,
        keywords: `${subject}, ${city}, ${state}, cannabis dispensary, weed shop, marijuana near me, SLS Dispensary, ${city} cannabis, ${subject.toLowerCase()} near ${city}, legal weed ${state}, recreational cannabis`,
        ogImage: "https://slsdispensary.com/images/dispensary/sls-baltimore-storefront.jpg",
    };
};

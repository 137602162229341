"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class NewsletterSignUp {
    constructor() {
        this.form = null;
        this.submitButton = null;
        this.notificationContainer = null;
        this.notificationIcon = null;
        this.notificationTitle = null;
        this.notificationMessage = null;
        this.successSvg = "";
        this.errorSvg = "";
        this.yesRadio = null;
        this.noRadio = null;
        this.phoneInput = null;
        document.addEventListener("DOMContentLoaded", this.init.bind(this));
    }
    init() {
        var _a, _b, _c;
        this.form = document.getElementById("newsletter-sign-up");
        if (!this.form)
            return; // Exit if the form doesn't exist
        this.submitButton = this.form.querySelector('button[type="submit"]');
        this.notificationContainer = document.getElementById("notification-container");
        this.notificationIcon = (_a = this.notificationContainer) === null || _a === void 0 ? void 0 : _a.querySelector("#notification-icon");
        this.notificationTitle = (_b = this.notificationContainer) === null || _b === void 0 ? void 0 : _b.querySelector("#notification-title");
        this.notificationMessage = (_c = this.notificationContainer) === null || _c === void 0 ? void 0 : _c.querySelector("#notification-message");
        this.successSvg = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12 text-green-400">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>`;
        this.errorSvg = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12 text-red-400">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>`;
        this.form.addEventListener("submit", this.handleSubmit.bind(this));
        this.yesRadio = document.getElementById("text_yes");
        this.noRadio = document.getElementById("text_no");
        this.phoneInput = document.getElementById("phone");
        if (this.yesRadio) {
            this.yesRadio.addEventListener("change", this.togglePhoneInput.bind(this));
        }
        if (this.noRadio) {
            this.noRadio.addEventListener("change", this.togglePhoneInput.bind(this));
        }
        this.togglePhoneInput();
    }
    togglePhoneInput() {
        if (this.phoneInput) {
            this.phoneInput.style.display =
                this.noRadio && this.noRadio.checked ? "none" : "block";
        }
    }
    handleSubmit(event) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            event.preventDefault();
            const emailPermission = (_c = (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.querySelector("#email_yes")) === null || _b === void 0 ? void 0 : _b.checked) !== null && _c !== void 0 ? _c : false;
            const formData = {
                given_name: (_f = (_e = (_d = this.form) === null || _d === void 0 ? void 0 : _d.querySelector("#firstName")) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : "",
                family_name: (_j = (_h = (_g = this.form) === null || _g === void 0 ? void 0 : _g.querySelector("#lastName")) === null || _h === void 0 ? void 0 : _h.value) !== null && _j !== void 0 ? _j : "",
                email: (_m = (_l = (_k = this.form) === null || _k === void 0 ? void 0 : _k.querySelector("#email")) === null || _l === void 0 ? void 0 : _l.value) !== null && _m !== void 0 ? _m : "",
                phone_number: this.formatPhoneNumber((_p = (_o = this.form) === null || _o === void 0 ? void 0 : _o.querySelector("#phone")) === null || _p === void 0 ? void 0 : _p.value),
                text_updates: (_s = (_r = (_q = this.form) === null || _q === void 0 ? void 0 : _q.querySelector("#text_yes")) === null || _r === void 0 ? void 0 : _r.checked) !== null && _s !== void 0 ? _s : false,
                event_updates: emailPermission,
                promotional_offer: emailPermission,
                marketing_consent: emailPermission,
                privacy_policy_consent: emailPermission,
            };
            if (this.submitButton) {
                this.submitButton.disabled = true;
                this.submitButton.textContent = "Sending...";
            }
            try {
                const response = yield fetch("https://x4iemzhvq5.execute-api.us-east-1.amazonaws.com/PROD/newsletter-sign-up", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });
                const data = yield response.json();
                const statusCode = data["statusCode"];
                if (statusCode === 201) {
                    this.showNotification("Success! 🎉", "Thank you for signing up! Welcome to the SLS family! 🌟", true);
                    (_t = this.form) === null || _t === void 0 ? void 0 : _t.reset();
                }
                else if (statusCode === 409) {
                    this.showNotification("Already Subscribed! 📬", "Looks like you're already part of the SLS family! Check your inbox for our latest updates. 😊", true);
                }
                else {
                    this.showNotification("Oops! Error 😕", "There was an error submitting the form. Please try again. We appreciate your patience! 🙏", false);
                }
            }
            catch (error) {
                console.error("Error:", error);
                this.showNotification("Network Error 🌐❌", "There was a network error. Please check your connection and try again later. We're sorry for the inconvenience! 😔", false);
            }
            finally {
                if (this.submitButton) {
                    this.submitButton.disabled = false;
                    this.submitButton.textContent = "Send It";
                }
            }
        });
    }
    formatPhoneNumber(phone) {
        return "+1" + (phone !== null && phone !== void 0 ? phone : "").replace(/\D/g, "");
    }
    showNotification(title, message, isSuccess) {
        if (!this.notificationContainer) {
            console.warn("Notification container not found. Falling back to alert.");
            alert(`${title}\n${message}`);
            return;
        }
        if (this.notificationIcon)
            this.notificationIcon.innerHTML = isSuccess
                ? this.successSvg
                : this.errorSvg;
        if (this.notificationTitle)
            this.notificationTitle.textContent = title;
        if (this.notificationMessage)
            this.notificationMessage.textContent = message;
        this.notificationContainer.classList.remove("hidden");
        if (typeof gsap !== "undefined") {
            gsap.fromTo(this.notificationContainer, {
                y: -50,
                opacity: 0,
                scale: 0.9,
            }, {
                duration: 0.5,
                y: 0,
                opacity: 1,
                scale: 1,
                ease: "back.out(1.7)",
            });
            gsap.delayedCall(4.2, this.hideNotification.bind(this));
        }
    }
    hideNotification() {
        if (!this.notificationContainer)
            return;
        if (typeof gsap !== "undefined") {
            gsap.to(this.notificationContainer, {
                duration: 0.5,
                y: -50,
                opacity: 0,
                scale: 0.9,
                ease: "power2.in",
                onComplete: () => { var _a; return (_a = this.notificationContainer) === null || _a === void 0 ? void 0 : _a.classList.add("hidden"); },
            });
        }
        else {
            this.notificationContainer.classList.add("hidden");
        }
    }
}
new NewsletterSignUp();

export const BUSINESS_NAME = "SLS Dispensary";
export const BASE_URL = "slsdispensary.com";
export const EMAIL = "your-email@example.com";
export const INSTAGRAM_URL = "https://www.instagram.com/slsdispensarydc";
export const FACEBOOK_URL = "https://www.facebook.com/p/Street-Lawyer-Services-DC-100083761881964/";
export const BUSINESS_HOURS = {
    Monday: { open: "10:00", close: "22:00" },
    Tuesday: { open: "10:00", close: "22:00" },
    Wednesday: { open: "10:00", close: "22:00" },
    Thursday: { open: "10:00", close: "22:00" },
    Friday: { open: "10:00", close: "22:00" },
    Saturday: { open: "10:00", close: "22:00" },
    Sunday: { open: "10:00", close: "22:00" },
};

import { BUSINESS_NAME, BASE_URL } from "@/core/constants/business";
/**
 * Generates structured data for blog articles based on the content and location
 * @param dataItem - Object containing subject, city and state data
 * @param title - The title of the blog post
 * @param metaDescription - Meta description of the blog post
 * @param datePublished - Date when the blog was published (defaults to current date if not provided)
 * @param dateModified - Date when the blog was last modified (defaults to current date if not provided)
 * @returns JSON string of structured data compliant with schema.org Article
 */
export const generateBlogArticleSchema = (dataItem, title, metaDescription, datePublished, dateModified) => {
    const today = new Date().toISOString();
    const canonicalPath = `/${dataItem.subject
        .toLowerCase()
        .replace(/\s+/g, "-")}/${dataItem.city
        .toLowerCase()
        .replace(/\s+/g, "-")}-${dataItem.state.toLowerCase()}`;
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": `https://${BASE_URL}${canonicalPath}`,
        },
        headline: title,
        description: metaDescription,
        image: [
            `https://${BASE_URL}/images/dispensary/sls-baltimore-storefront.jpg`,
            `https://${BASE_URL}/images/website/street-lawyer-services-weed-dispensary-dc-75.jpg`,
        ],
        datePublished: datePublished || today,
        dateModified: dateModified || today,
        author: {
            "@type": "Organization",
            name: BUSINESS_NAME,
            url: `https://${BASE_URL}`,
            telephone: "4436530028",
            image: `https://${BASE_URL}/images/sls-logo.png`,
            // Add missing address field for Organization
            address: {
                "@type": "PostalAddress",
                streetAddress: "101 E Chesapeake Avenue",
                addressLocality: "Towson",
                addressRegion: "MD",
                postalCode: "21286",
                addressCountry: "US",
            },
        },
        publisher: {
            "@type": "Organization",
            name: BUSINESS_NAME,
            logo: {
                "@type": "ImageObject",
                url: `https://${BASE_URL}/images/sls-logo.png`,
            },
            telephone: "4436530028",
            image: `https://${BASE_URL}/images/website/street-lawyer-services-weed-dispensary-dc-75.jpg`,
            // Add missing address field for Organization
            address: {
                "@type": "PostalAddress",
                streetAddress: "101 E Chesapeake Avenue",
                addressLocality: "Towson",
                addressRegion: "MD",
                postalCode: "21286",
                addressCountry: "US",
            },
        },
        keywords: [
            dataItem.subject,
            dataItem.city,
            dataItem.state,
            "cannabis",
            "dispensary",
            "weed",
            "marijuana",
            "SLS Dispensary",
        ],
        about: {
            "@type": "Thing",
            name: `${dataItem.subject} in ${dataItem.city}, ${dataItem.state}`,
            description: `Information about ${dataItem.subject.toLowerCase()} in ${dataItem.city}, ${dataItem.state}`,
            image: `https://${BASE_URL}/images/dispensary/sls-baltimore-storefront.jpg`,
        },
        isPartOf: {
            "@type": "Blog",
            name: `${BUSINESS_NAME} Blog`,
            publisher: {
                "@type": "Organization",
                name: BUSINESS_NAME,
                image: `https://${BASE_URL}/images/sls-logo.png`,
                telephone: "4436530028", // Added telephone to nested Organization
                address: {
                    "@type": "PostalAddress",
                    streetAddress: "101 E Chesapeake Avenue",
                    addressLocality: "Towson",
                    addressRegion: "MD",
                    postalCode: "21286",
                    addressCountry: "US",
                },
            },
        },
        locationCreated: {
            "@type": "Place",
            address: {
                "@type": "PostalAddress",
                addressLocality: "Towson",
                addressRegion: "MD",
                postalCode: "21286",
                streetAddress: "101 E Chesapeake Avenue",
                addressCountry: "US",
            },
        },
        mentions: {
            "@type": "Place",
            name: dataItem.city,
            containedInPlace: {
                "@type": "State",
                name: dataItem.state === "MD" ? "Maryland" : dataItem.state,
            },
        },
    });
};
/**
 * Generates structured data for Cannabis Dispensary Service
 * @param dataItem - Object containing subject, city and state data
 * @param serviceDescription - Description of the service offered
 * @returns JSON string of structured data compliant with schema.org Service
 */
export const generateDispensaryServiceSchema = (dataItem, serviceDescription) => {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Service",
        serviceType: dataItem.subject,
        provider: {
            "@type": "LocalBusiness",
            "@id": `https://${BASE_URL}/#dispensary`,
            name: BUSINESS_NAME,
            url: `https://${BASE_URL}`,
            telephone: "4436530028",
            priceRange: "$$",
            image: [
                `https://${BASE_URL}/images/website/street-lawyer-services-weed-dispensary-dc-75.jpg`,
                `https://${BASE_URL}/images/sls-logo.png`,
            ],
            address: {
                "@type": "PostalAddress",
                streetAddress: "101 E Chesapeake Avenue",
                addressLocality: "Towson",
                addressRegion: "MD",
                postalCode: "21286",
                addressCountry: "US",
            },
        },
        areaServed: {
            "@type": "City",
            name: dataItem.city,
            containedInPlace: {
                "@type": "State",
                name: dataItem.state === "MD" ? "Maryland" : dataItem.state,
            },
        },
        description: serviceDescription,
        offers: {
            "@type": "Offer",
            itemOffered: {
                "@type": "Product",
                name: `Cannabis Products for ${dataItem.city} area`,
                category: "Cannabis",
                image: `https://${BASE_URL}/images/dispensary/sls-baltimore-storefront.jpg`,
                offers: {
                    "@type": "Offer",
                    price: "35.00",
                    priceCurrency: "USD",
                    availability: "https://schema.org/InStock",
                    url: `https://${BASE_URL}/towson-md`,
                },
                aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: "4.8",
                    reviewCount: "127",
                },
            },
            areaServed: dataItem.city,
        },
    });
};
/**
 * Generates breadcrumb structured data for blog pages
 * @param dataItem - Object containing subject, city and state data
 * @returns JSON string of structured data compliant with schema.org BreadcrumbList
 */
export const generateBreadcrumbSchema = (dataItem) => {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
            {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: `https://${BASE_URL}/`,
            },
            {
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: `https://${BASE_URL}/blog`,
            },
            {
                "@type": "ListItem",
                position: 3,
                name: dataItem.subject,
                item: `https://${BASE_URL}/${dataItem.subject
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`,
            },
            {
                "@type": "ListItem",
                position: 4,
                name: `${dataItem.subject} in ${dataItem.city}, ${dataItem.state}`,
                item: `https://${BASE_URL}/${dataItem.subject
                    .toLowerCase()
                    .replace(/\s+/g, "-")}/${dataItem.city
                    .toLowerCase()
                    .replace(/\s+/g, "-")}-${dataItem.state.toLowerCase()}`,
            },
        ],
    });
};
/**
 * Generates all required rich snippets for a blog page
 * @param dataItem - Object containing subject, city and state data
 * @param title - The title of the blog post
 * @param metaDescription - Meta description of the blog post
 * @returns Object containing all structured data JSON strings
 */
export const generateBlogRichSnippets = (dataItem, title, metaDescription) => {
    return {
        articleSchema: generateBlogArticleSchema(dataItem, title, metaDescription),
        dispensaryServiceSchema: generateDispensaryServiceSchema(dataItem, metaDescription),
        breadcrumbSchema: generateBreadcrumbSchema(dataItem),
    };
};

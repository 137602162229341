import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { useAuth } from "@/core/context/AuthContext";
import SideMenu from "@/core/dashboard/SideMenu";
import Home from "./Home";
import Orders from "./Orders";
import Account from "./Account";
import Settings from "./Settings";
import LoyaltyPoints from "./LoyaltyPoints";
import EmailList from "./EmailList";
import PhoneNumbers from "./PhoneNumbers"; // Import the PhoneNumbers component
const Dashboard = () => {
    const { isAuthenticated, userRole } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
        else {
            console.log("User is authenticated");
            console.log("User role:", userRole);
            console.log("Active dashboard:", userRole === "employee" ? "Employee Dashboard" : "Customer Dashboard");
        }
    }, [isAuthenticated, userRole, navigate]);
    if (!isAuthenticated) {
        return null;
    }
    return (_jsxs("div", { className: "flex", children: [_jsx(SideMenu, {}), _jsx("div", { className: "w-full p-4 overflow-x-hidden lg:p-8", children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "orders", element: _jsx(Orders, {}) }), _jsx(Route, { path: "account", element: _jsx(Account, {}) }), userRole === "employee" && (_jsx(Route, { path: "settings", element: _jsx(Settings, {}) })), userRole === "employee" && (_jsx(Route, { path: "email-list", element: _jsx(EmailList, {}) })), userRole === "employee" && (_jsx(Route, { path: "phone-numbers", element: _jsx(PhoneNumbers, {}) }) // Add the PhoneNumbers route
                        ), userRole === "customer" && (_jsx(Route, { path: "loyalty-points", element: _jsx(LoyaltyPoints, {}) }))] }) })] }));
};
export default Dashboard;

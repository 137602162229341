var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_API_URL, ENDPOINTS } from "@/core/api/endpoints";
const PhoneNumbers = () => {
    const [phones, setPhones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPhones, setTotalPhones] = useState(0);
    const fetchPhones = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setLoading(true);
        console.log("Fetching phones"); // Debugging log
        try {
            const response = yield axios.get(`${BASE_API_URL}${ENDPOINTS.GET_PHONE_NUMBER_LIST}`, // Use the GET_PHONE_NUMBER_LIST endpoint
            {
                params: {
                    limit: 100,
                },
            });
            const responseData = JSON.parse(response.data.body);
            console.log("API response:", responseData); // Debugging log
            const newPhones = ((_a = responseData.phone_numbers) === null || _a === void 0 ? void 0 : _a.map((item) => {
                var _a;
                return ({
                    phone_number: ((_a = item.phone_number) === null || _a === void 0 ? void 0 : _a.replace(/\.0$/, "")) || "",
                    first_name: item.first_name || "",
                    last_name: item.last_name || "",
                });
            }).filter((item) => item.phone_number !== "")) || [];
            console.log("Mapped phones:", newPhones); // Debugging log
            setPhones(newPhones);
        }
        catch (err) {
            console.error("Failed to fetch phones:", err);
            setError("Failed to fetch phones");
        }
        finally {
            setLoading(false);
        }
    });
    const fetchTotalPhones = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${BASE_API_URL}${ENDPOINTS.GET_PHONE_NUMBER_LIST_COUNT}`);
            const responseData = JSON.parse(response.data.body);
            console.log("Total phone numbers count:", responseData.total);
            setTotalPhones(responseData.total);
        }
        catch (err) {
            console.error("Failed to fetch total phone numbers count:", err);
        }
    });
    useEffect(() => {
        fetchPhones();
        fetchTotalPhones();
    }, []);
    if (loading && phones.length === 0) {
        return _jsx("div", { className: "font-semibold boujee-text", children: "Loading..." });
    }
    if (error) {
        return _jsx("div", { children: error });
    }
    return (_jsxs("div", { className: "p-4 bg-transparent", children: [_jsx("h2", { className: "mb-4 text-2xl font-bold boujee-text", children: "Phone Numbers" }), _jsxs("p", { className: "mb-4 boujee-text", children: ["Total Phone Numbers: ", totalPhones] }), _jsx("div", { className: "max-w-full overflow-x-auto", children: _jsx("div", { className: "max-h-96", children: _jsxs("table", { className: "w-full bg-transparent border border-gray-200 table-auto", children: [_jsx("thead", { children: _jsxs("tr", { className: "text-left text-white", children: [_jsx("th", { className: "px-4 py-2 border-b", children: "#" }), _jsx("th", { className: "px-4 py-2 border-b", children: "Name" }), _jsx("th", { className: "px-4 py-2 border-b", children: "Phone" })] }) }), _jsx("tbody", { className: "max-w-lg", children: phones.map((phone, index) => (_jsxs("tr", { className: "w-full text-white hover:bg-gray-100 hover:text-primary", children: [_jsx("td", { className: "w-8 px-4 py-2 border-b", children: index + 1 }), _jsx("td", { className: "px-4 py-2 text-white break-words whitespace-normal border-b", children: phone.first_name }), _jsx("td", { className: "px-4 py-2 text-white break-words whitespace-normal border-b", children: phone.phone_number })] }, index))) })] }) }) })] }));
};
export default PhoneNumbers;

//src/core/api/endpoints.ts
export const ENDPOINTS = {
    SEND_CONTACT_FORM: "/contact-form",
    GET_PRODUCTS: "/fetch-product-data",
    CHECKOUT_CART: "/checkout-v2",
    SEND_CONFIRMATION_EMAIL: "/send-confirmation-email",
    REGISTER_USER: "/registration",
    LOGIN: "/login",
    GET_EMAIL_LIST: "/get-email-list",
    GET_EMAIL_LIST_COUNT: "/get-email-list-total-count",
    GET_USER_DATA: "/get-user-data",
    GET_PHONE_NUMBER_LIST: "/get-phone-number-list",
    GET_PHONE_NUMBER_LIST_COUNT: "/get-phone-number-list-count",
};
const STAGING_NAME = "PROD";
const AWS_REGION = "us-east-1";
const AWS_API_GATEWAY_ID = "x4iemzhvq5";
const AWS_API_GATEWAY_URL = `https://${AWS_API_GATEWAY_ID}.execute-api.${AWS_REGION}.amazonaws.com`;
export const BASE_API_URL = `${AWS_API_GATEWAY_URL}/${STAGING_NAME}`;

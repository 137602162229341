var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/core/Cart/UserInformationForm.tsx
import { useState } from "react";
import { useCart } from "@/core/context/CartContext";
import { ClipLoader } from "react-spinners";
import { BASE_API_URL, ENDPOINTS } from "@/core/api/endpoints";
import Toast from "../ui/Toast";
const UserInformationForm = ({ onBack, onSubmit, }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const { state } = useCart();
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState("success");
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setLoading(true);
        try {
            const cartItems = state.items.map((item) => ({
                product_id: item.id,
                quantity: item.quantity,
                price: item.price,
            }));
            const userInfo = {
                name: name,
                email: email,
                cart: cartItems,
            };
            const response = yield fetch(`${BASE_API_URL}${ENDPOINTS.CHECKOUT_CART}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userInfo),
            });
            const data = yield response.json();
            if (response.status === 201) {
                setToastType("success");
                setToastMessage(data.body);
            }
            else {
                setToastType("error");
                setToastMessage(data.body);
            }
        }
        catch (error) {
            console.error("Checkout error:", error);
            setToastType("error");
            setToastMessage("An error occurred during checkout. Please try again. " + error.message);
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsxs("div", { children: [_jsxs("form", { onSubmit: handleSubmit, className: "p-4 space-y-4", children: [_jsxs("div", { children: [_jsx("label", { className: "block text-sm font-medium text-gray-700", children: "Name" }), _jsx("input", { type: "text", value: name, onChange: (e) => setName(e.target.value), className: "block w-full px-3 py-2 mt-1 border rounded-md", required: true })] }), _jsxs("div", { children: [_jsx("label", { className: "block text-sm font-medium text-gray-700", children: "Email" }), _jsx("input", { type: "email", value: email, onChange: (e) => setEmail(e.target.value), className: "block w-full px-3 py-2 mt-1 border rounded-md", required: true })] }), _jsxs("div", { className: "flex justify-between", children: [_jsx("button", { type: "button", onClick: onBack, className: "px-4 py-2 text-white bg-[#203a43] rounded", children: "Back" }), _jsx("button", { type: "submit", className: "px-4 py-2 text-white bg-[#203a43] rounded", disabled: loading, children: loading ? (_jsx(ClipLoader, { color: "#ffffff", size: 20 })) : ("Complete Purchase") })] })] }), toastMessage && (_jsx(Toast, { message: toastMessage, type: toastType, onClose: () => setToastMessage(null) }))] }));
};
export default UserInformationForm;

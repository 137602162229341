import { BUSINESS_NAME, BUSINESS_HOURS } from "@/core/constants/business";
import { LOCATIONS } from "@/core/constants/locations";
export const generateHomePageRichSnippet = () => {
    // Get the Washington DC and Baltimore locations
    const dcLocation = LOCATIONS.find((loc) => loc.name === "Washington DC");
    const baltimoreLocation = LOCATIONS.find((loc) => loc.name === "Baltimore County");
    // Format the business hours for the rich snippet
    const openingHoursSpecification = Object.entries(BUSINESS_HOURS).map(([day, hours]) => ({
        "@type": "OpeningHoursSpecification",
        dayOfWeek: day,
        opens: hours.open,
        closes: hours.close,
    }));
    // Hardcoded coordinates since they're not in the Location interface
    const coordinates = {
        "Washington DC": { latitude: 38.9392, longitude: -77.0709 }, // Connecticut Ave coordinates
        "Baltimore County": { latitude: 39.4015, longitude: -76.6019 }, // Towson coordinates
    };
    // Location-specific images
    const locationImages = {
        "Washington DC": "https://slsdispensary.com/images/website/street-lawyer-services-weed-dispensary-dc-75.jpg",
        "Baltimore County": "https://slsdispensary.com/images/website/street-lawyer-services-weed-dispensary-baltimore-75.jpg",
    };
    // Create array of all locations
    const locations = LOCATIONS.map((location) => {
        var _a, _b;
        return ({
            "@type": "Store",
            name: `${BUSINESS_NAME} - ${location.name}`,
            telephone: location.phoneNumber,
            priceRange: "$$",
            image: locationImages[location.name] ||
                "https://slsdispensary.com/images/sls-logo.png",
            address: {
                "@type": "PostalAddress",
                streetAddress: location.address1,
                addressLocality: location.city,
                addressRegion: location.state,
                postalCode: location.zip,
                addressCountry: "US",
            },
            geo: {
                "@type": "GeoCoordinates",
                latitude: ((_a = coordinates[location.name]) === null || _a === void 0 ? void 0 : _a.latitude) || 0,
                longitude: ((_b = coordinates[location.name]) === null || _b === void 0 ? void 0 : _b.longitude) || 0,
            },
            // Don't include URLs in the department items to avoid duplicates
            // Instead use the specific Shop URL as ID
            "@id": `https://slsdispensary.com/${location.shopURL}#location`,
            openingHoursSpecification,
        });
    });
    // Main Organization schema
    const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: BUSINESS_NAME,
        alternateName: "SLS Dispensary",
        url: "https://slsdispensary.com",
        logo: "https://slsdispensary.com/images/sls-logo.png",
        description: "Premium cannabis dispensary in Washington DC and Baltimore offering top-shelf flower, edibles, and concentrates from brands like Cookies and Pink Rozay.",
        sameAs: [
            "https://www.instagram.com/slsdispensary/",
            "https://www.instagram.com/slsdispensarydc/",
            "https://www.facebook.com/profile.php?id=100083761881964",
            "https://x.com/DCStreetLawyer",
        ],
        contactPoint: {
            "@type": "ContactPoint",
            telephone: (baltimoreLocation === null || baltimoreLocation === void 0 ? void 0 : baltimoreLocation.phoneNumber) || "",
            contactType: "customer service",
            areaServed: ["Washington DC", "Baltimore"],
            availableLanguage: "English",
        },
        location: locations,
    };
    // Local business schema
    const localBusinessSchema = {
        "@context": "https://schema.org",
        "@type": "CannabisDispensary",
        name: BUSINESS_NAME,
        image: [
            "https://slsdispensary.com/images/website/street-lawyer-services-weed-dispensary-dc-75.jpg",
            "https://slsdispensary.com/images/website/street-lawyer-services-weed-dispensary-baltimore-75.jpg",
            "https://slsdispensary.com/images/sls-logo.png",
        ],
        "@id": "https://slsdispensary.com/#dispensary",
        url: "https://slsdispensary.com",
        telephone: (baltimoreLocation === null || baltimoreLocation === void 0 ? void 0 : baltimoreLocation.phoneNumber) || "",
        priceRange: "$$",
        address: {
            "@type": "PostalAddress",
            streetAddress: (baltimoreLocation === null || baltimoreLocation === void 0 ? void 0 : baltimoreLocation.address1) || "",
            addressLocality: (baltimoreLocation === null || baltimoreLocation === void 0 ? void 0 : baltimoreLocation.city) || "",
            addressRegion: (baltimoreLocation === null || baltimoreLocation === void 0 ? void 0 : baltimoreLocation.state) || "",
            postalCode: (baltimoreLocation === null || baltimoreLocation === void 0 ? void 0 : baltimoreLocation.zip) || "",
            addressCountry: "US",
        },
        geo: {
            "@type": "GeoCoordinates",
            latitude: coordinates["Baltimore County"].latitude || 39.4015,
            longitude: coordinates["Baltimore County"].longitude || -76.6019,
        },
        openingHoursSpecification,
        // Use hasMap for department references to avoid URL duplication
        hasMap: LOCATIONS.map((location) => ({
            "@id": `https://slsdispensary.com/${location.shopURL}#location`,
        })),
        // Remove department field that was causing duplicate URLs
    };
    return {
        organization: JSON.stringify(organizationSchema),
        localBusiness: JSON.stringify(localBusinessSchema),
    };
};

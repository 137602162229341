import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const featuredVideos = [
    {
        title: "Lonny The Street Lawyer Interview",
        embedId: "Y-QjLUhdGdA",
        description: "In-depth interview with Lonny discussing the cannabis industry and legal advocacy",
    },
    {
        title: "Featured YouTube Interview",
        embedId: "tqYE2rUOViY",
        description: "Exclusive interview about Street Lawyer Services' mission and vision",
    },
    {
        title: "Cannabis Advocacy",
        embedId: "1TUqIIH65Z8",
        description: "Lonny discusses cannabis advocacy and reform in Washington DC",
    },
    {
        title: "The Weed Show with Lonny",
        embedId: "ngP6-dlLP7A",
        description: "Special episode featuring discussions about cannabis culture and trends",
    },
    {
        title: "Street Lawyer Services Documentary",
        embedId: "5Ffb8snADkY",
        description: "Behind the scenes look at SLS operations and community impact",
    },
    {
        title: "Cannabis Industry Insights",
        embedId: "4nDXQHkPgvg",
        description: "Expert analysis on the evolving cannabis market and regulations",
    },
    {
        title: "Interview with ONLY1 THEORY & Mista Thang |Zeus Reality Dating Show 'Two Ways with Erica Mena'",
        embedId: "OR3FkuEVX9I",
        description: "In this exclusive episode of the Street Lawyer Podcast, host Lonny The Street Lawyer sits down with Zeus Network cast members ONLY1 THEORY (Goldie Loccs) and Jason Laron (Mista Thang) from the hit reality dating show, Two Ways with Erica Mena. Get the inside scoop on their experiences in the house, Erica Mena,  navigating their careers outside of reality tv And Virginity? Tune in for an in-depth conversation as Goldie Loccs and Mista Thang share behind-the-scenes stories, personal insights, and what’s next for this groundbreaking show.",
    },
];
const FeaturedVideos = ({ limit = 3 }) => {
    // Only show the specified number of videos
    const videosToShow = featuredVideos.slice(0, limit);
    return (_jsxs("div", { className: "w-11/12 max-w-6xl mx-auto", children: [_jsx("h2", { className: "mb-6 text-3xl font-semibold boujee-text", children: "Featured Videos" }), _jsx("div", { className: "grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3", children: videosToShow.map((video, index) => (_jsxs("div", { className: "overflow-hidden rounded-lg shadow-lg", children: [_jsx("iframe", { className: "w-full aspect-video", src: `https://www.youtube.com/embed/${video.embedId}`, title: video.title, frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }), _jsxs("div", { className: "p-4 bg-gray-800", children: [_jsx("h3", { className: "text-lg font-medium", children: video.title }), video.description && (_jsx("p", { className: "mt-1 text-sm text-gray-300", children: video.description }))] })] }, index))) }), _jsx("div", { className: "mt-8 text-center", children: _jsxs("a", { href: "https://www.youtube.com/c/StreetLawyerServices", target: "_blank", rel: "noreferrer", className: "inline-flex items-center px-6 py-3 font-semibold text-white rounded-full gradient-anime-alert", children: [_jsx("i", { className: "mr-2 fa-brands fa-youtube" }), "View More Videos"] }) })] }));
};
export default FeaturedVideos;

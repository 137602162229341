// src/core/template/Reviews/reviews-rich-snippet.ts
import { BUSINESS_NAME } from "@/core/constants/business";
export const generateReviewsRichSnippet = (reviews) => {
    // Filter to make sure we only include reviews with ratings
    const validReviews = reviews.filter((review) => review.rating);
    // Calculate average rating
    const totalRating = validReviews.reduce((sum, review) => sum + (review.rating || 5), 0);
    const averageRating = totalRating / validReviews.length;
    // Create the schema
    const reviewSchema = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "@id": "https://slsdispensary.com/#dispensary",
        name: BUSINESS_NAME,
        url: "https://slsdispensary.com",
        image: "https://slsdispensary.com/images/sls-logo.png",
        priceRange: "$$",
        telephone: "4436530028",
        address: {
            "@type": "PostalAddress",
            streetAddress: "101 E Chesapeake Avenue",
            addressLocality: "Towson",
            addressRegion: "MD",
            postalCode: "21286",
            addressCountry: "US",
        },
        aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: averageRating.toFixed(1),
            reviewCount: validReviews.length,
            bestRating: "5",
            worstRating: "1",
        },
        review: validReviews.slice(0, 10).map((review) => ({
            "@type": "Review",
            author: {
                "@type": "Person",
                name: review.name || "Anonymous Customer",
            },
            datePublished: new Date().toISOString().split("T")[0],
            reviewBody: review.review || "",
            name: `${review.name}'s review of ${BUSINESS_NAME}`,
            reviewRating: {
                "@type": "Rating",
                ratingValue: review.rating || 5,
                bestRating: "5",
                worstRating: "1",
            },
        })),
    };
    return JSON.stringify(reviewSchema);
};

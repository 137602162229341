import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer, useContext, useEffect } from "react";
const initialState = {
    items: [],
};
const CartContext = createContext({
    state: initialState,
    dispatch: () => null,
});
const cartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            const existingItem = state.items.find((item) => item.id === action.payload.id);
            if (existingItem) {
                return Object.assign(Object.assign({}, state), { items: state.items.map((item) => item.id === action.payload.id
                        ? Object.assign(Object.assign({}, item), { quantity: item.quantity + 1 }) : item) });
            }
            return Object.assign(Object.assign({}, state), { items: [...state.items, action.payload] });
        case "UPDATE_QUANTITY":
            return Object.assign(Object.assign({}, state), { items: state.items.map((item) => item.id === action.payload.id ? action.payload : item) });
        case "REMOVE_ITEM":
            return Object.assign(Object.assign({}, state), { items: state.items.filter((item) => item.id !== action.payload.id) });
        case "CLEAR_CART":
            return Object.assign(Object.assign({}, state), { items: [] });
        case "INITIALIZE_CART":
            return Object.assign(Object.assign({}, state), { items: Array.isArray(action.payload) ? action.payload : [] });
        default:
            return state;
    }
};
export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);
    useEffect(() => {
        const storedCart = localStorage.getItem("cart");
        if (storedCart) {
            try {
                const parsedCart = JSON.parse(storedCart);
                const cartItems = Object.values(parsedCart);
                dispatch({ type: "INITIALIZE_CART", payload: cartItems });
            }
            catch (e) {
                console.error("Error parsing stored cart:", e);
            }
        }
    }, []);
    // Sync state changes to localStorage
    useEffect(() => {
        const cartObject = state.items.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});
        localStorage.setItem("cart", JSON.stringify(cartObject));
    }, [state]);
    return (_jsx(CartContext.Provider, { value: { state, dispatch }, children: children }));
};
export const useCart = () => useContext(CartContext);

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
const Toast = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 3000);
        return () => clearTimeout(timer);
    }, [onClose]);
    return (_jsx("div", { className: `fixed top-1/2 inset-x-0 font-semibold tracking-wide mx-auto p-4 rounded shadow-lg max-w-xs text-center z-50 ${type === "success"
            ? "gradient-success text-white"
            : "gradient-error text-white"} `, style: { transition: "transform 0.3s ease, opacity 0.3s ease" }, children: message }));
};
export default Toast;

// src/core/utils/notification.js

const successSvg = `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12 text-green-400">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>`;

const errorSvg = `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-12 h-12 text-red-400">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>`;

export const showNotification = (title, message, isSuccess) => {
  const notificationContainer = document.getElementById(
    "notification-container"
  );
  const notificationIcon = document.getElementById("notification-icon");
  const notificationTitle = document.getElementById("notification-title");
  const notificationMessage = document.getElementById("notification-message");

  if (!notificationContainer) {
    console.warn("Notification container not found. Falling back to alert.");
    alert(`${title}\n${message}`);
    return;
  }

  if (notificationIcon)
    notificationIcon.innerHTML = isSuccess ? successSvg : errorSvg;
  if (notificationTitle) notificationTitle.textContent = title;
  if (notificationMessage) notificationMessage.textContent = message;

  notificationContainer.classList.remove("hidden");

  if (typeof gsap !== "undefined") {
    gsap.fromTo(
      notificationContainer,
      {
        y: -50,
        opacity: 0,
        scale: 0.9,
      },
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
        scale: 1,
        ease: "back.out(1.7)",
      }
    );
    gsap.delayedCall(4.2, hideNotification);
  }
};

export const hideNotification = () => {
  const notificationContainer = document.getElementById(
    "notification-container"
  );

  if (!notificationContainer) return;

  if (typeof gsap !== "undefined") {
    gsap.to(notificationContainer, {
      duration: 0.5,
      y: -50,
      opacity: 0,
      scale: 0.9,
      ease: "power2.in",
      onComplete: () => notificationContainer.classList.add("hidden"),
    });
  } else {
    notificationContainer.classList.add("hidden");
  }
};
